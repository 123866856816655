import {IGalleryGlobalProps, IGlobals} from '../../galleryGlobalStrategy';
import _ from 'lodash';
import {IGalleryAppProps} from '../../../common/ExtendedGridGallery/ExtendedGridGallery';
import type {FilterModel} from '../../../types/galleryTypes';

export const shouldShowGalleryAppInLayout = (isLoaded, {showShowLightEmptyState}: IGlobals) => {
  return isLoaded && !showShowLightEmptyState;
};

export const hasRelevantFilters = (filterModels: FilterModel[]) => {
  return filterModels.length > 0;
};

export const shouldRenderSortInLayout = ({shouldShowSort}: IGlobals) => {
  return shouldShowSort;
};

export const announceIfFilterResultChanged = (prevProps: IGalleryGlobalProps, props: IGalleryAppProps, cb) => {
  const haveFiltersChanged = !_.isEqual(props.globals.filterModels, prevProps.globals.filterModels);

  if (haveFiltersChanged && props.globals.products) {
    cb();
  }
};
