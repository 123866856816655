import React, {useRef} from 'react';
import s from './AddToCartButton.scss';
import {ButtonContentType, IProduct} from '../../../../types/galleryTypes';
import classNames from 'classnames';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa/cssVars';
import {classes as addToCartStyle, st as addToCartStyles} from './AddToCartButton.st.css';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {AddToCartActionStatus} from '../../../../constants';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {useAddToCartState} from './hooks/useAddToCartState';
import {useAddToCartIcon} from './hooks/useAddToCartIcon';
import {IconStatesButton} from './IconStatesButton/IconStatesButton';
import {useAddToCartNotificationEnd} from './hooks/useAddToCartNotificationEnd';

export enum DataHook {
  AddToCartButton = 'product-item-add-to-cart-button',
  AddToCartLoadingIndicator = 'product-item-add-to-cart-loading-indicator',
}

export interface IAddToCart extends IGalleryGlobalProps {
  index: number;
  product: IProduct;
  quantity: number;
  showOnHoverClassName: string;
  onAddToCartClicked(): void;

  addedToCartStatus: {
    [productId: string]: AddToCartActionStatus;
  };
  handleAddToCart: ({productId, index, quantity}: {productId: string; index: number; quantity: number}) => void;
  updateAddToCartStatus: (productId: string, status: AddToCartActionStatus) => void;

  galleryAddToCartButtonText: string;
  addToCartContactSeller: string;
  addToCartOutOfStock: string;
  galleryAddToCartPreOrderButtonText: string;
  shouldShowAddToCartSuccessAnimation: boolean;

  shouldShowMobile: boolean;
  shouldShowOnHover: boolean;
  buttonWidth: string;
  contentType: ButtonContentType;
}

export const AddToCartComp = (props: IAddToCart) => {
  const {
    product: {id: productId},
    showOnHoverClassName,
    shouldShowMobile,
    shouldShowOnHover,
    contentType,
    galleryAddToCartButtonText,
    addToCartContactSeller,
    addToCartOutOfStock,
    galleryAddToCartPreOrderButtonText,
    index,
    quantity,
    handleAddToCart,
    onAddToCartClicked,
    globals: {productsManifest, styles, stylesParams},
  } = props;

  const addToCartButtonRef = useRef<StatesButton>(null);
  const {isAddToCartEnabled, getAddToCartButtonState} = useAddToCartState(productId);
  const {handleNotificationEnd} = useAddToCartNotificationEnd(productId);
  const {iconsMapper} = useAddToCartIcon();

  const {addToCartState} = productsManifest[productId];

  const handleAddToCartButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    handleAddToCart({productId, index, quantity});
    onAddToCartClicked();
  };

  const getAddToCartButtonText = (): string => {
    if (addToCartState === AddToCartState.DISABLED) {
      return addToCartContactSeller;
    }
    if (addToCartState === AddToCartState.OUT_OF_STOCK) {
      return addToCartOutOfStock;
    }
    if (addToCartState === AddToCartState.PRE_ORDER) {
      return galleryAddToCartPreOrderButtonText;
    }
    return galleryAddToCartButtonText;
  };

  const getIconCompForIconAndTextButton = () => {
    const icon = styles.get(stylesParams.gallery_addToCartButtonIcon);

    const Icon = iconsMapper[icon];
    return <Icon />;
  };

  const addToCartButtonText = getAddToCartButtonText();
  const isButtonEnabled = isAddToCartEnabled();
  const addToCartButtonState = getAddToCartButtonState();

  const addToCartButtonProps = {
    onClick: handleAddToCartButtonClick,
    disabled: !isButtonEnabled,
    className: classNames(
      s.addToCartBtn,
      addToCartStyles(addToCartStyle.addToCartButton, {
        useMobileFont: shouldShowMobile,
        isInStock: isButtonEnabled,
      }),
      {
        [showOnHoverClassName]: shouldShowOnHover && !shouldShowMobile,
        [s.iconButton]: contentType === ButtonContentType.Icon,
      }
    ),
    'data-hook': DataHook.AddToCartButton,
    'aria-label': addToCartButtonText,
    upgrade: contentType === ButtonContentType.IconAndText,
    fullWidth: true,
  };

  const stateButtonProps = {
    state: addToCartButtonState,
    prefixIcon:
      addToCartButtonState === StatesButtonStates.IDLE && contentType === ButtonContentType.IconAndText
        ? getIconCompForIconAndTextButton()
        : undefined,
    idleContent: addToCartButtonText,
    onNotificationEnd: handleNotificationEnd,
    ref: addToCartButtonRef,
  };

  return (
    <div className={s.addToCartBtnContainer}>
      {contentType === ButtonContentType.Icon ? (
        <IconStatesButton
          addToCartButtonProps={addToCartButtonProps}
          productId={productId}
          state={addToCartButtonState}
        />
      ) : (
        <StatesButton
          {...addToCartButtonProps}
          {...stateButtonProps}
          inProgressContent={
            <div className={addToCartStyle.threeDotsButtonWrapper}>
              <span className={s.dummyTextForLoadingWidth}>{addToCartButtonText}</span>
              <ThreeDotsLoader
                className={addToCartStyle.threeDotsButton}
                data-hook={DataHook.AddToCartLoadingIndicator}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export const AddToCartButton = withGlobals(withTranslations()(AddToCartComp));
