/* eslint-disable sonarjs/cognitive-complexity */
import React, {useEffect, useRef, useState} from 'react';
import s from './CategoryTree.scss';
import {classes as stylableClasses, cssStates} from './CategoryTree.st.css';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {ConditionalRender} from '../../../common/components/ConditionalRender/ConditionalRender';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {Experiments} from '../../../constants';
import {ICategory, ICategoryListItem} from '../../../types/category';
import {useCategories} from '../../hooks/useCategories';
import classNames from 'classnames';
import {CategoryListHeader} from './CategoryListHeader/CategoryListHeader';
import {ChevronDown, ChevronUp} from '@wix/wix-ui-icons-common';
import {CategoriesData} from '../../../services/CategoriesService';

export const CategoryTreeDataHook = {
  SectionTitle: 'category-tree-section-title',
  List: 'category-tree-list',
  BackButton: 'category-tree-back-button',
  ShowMoreButton: 'category-tree-show-more-button',
  CategoryHeader: 'category-tree-header',
  Container: 'category-tree-container',
  CategoryOption: {
    at: (i: number) => `category-option-${i}`,
  },
};

export const CategoryTree: React.FunctionComponent<IGalleryGlobalProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(({t, globals}: IGalleryGlobalProps & IProvidedTranslationProps) => {
    const htmlTag = globals.htmlTags.categoriesFiltersHtmlTag;
    const {experiments} = useExperiments();
    let categories: ICategoryListItem[];
    let currentCategory: ICategory;
    let listHeaderCategory: ICategoryListItem;
    let navigateToCategory: CategoriesData['navigateToCategory'];
    const [categoryListCollapsed, setCategoryListCollapsed] = useState<boolean>(true);
    const enableNewCategoryList = experiments.enabled(Experiments.NewCategoryList);
    if (enableNewCategoryList) {
      const {
        categoryList,
        listHeaderCategory: listHeader,
        currentCategory: _currentCategory,
        navigateToCategory: _navigateToCategory,
      } = useCategories();
      listHeaderCategory = listHeader;
      categories = categoryList;
      currentCategory = _currentCategory;
      navigateToCategory = _navigateToCategory;
    } else {
      categories = globals.categories;
      currentCategory = globals.currentCategory;
    }

    const categoryListRef = useRef<HTMLUListElement>();

    /* istanbul ignore next - hard to test with js dom */
    useEffect(() => {
      if (!categoryListRef.current) {
        return;
      }

      const {offsetTop: categoryListTop, clientHeight: categoryListHeight} = categoryListRef.current;
      const categoryListBottomPosition = categoryListTop + categoryListHeight;
      const categoryListItemElements = [...categoryListRef.current.childNodes] as HTMLLIElement[];

      categoryListItemElements.forEach((li) => ((li.firstChild as any).tabIndex = 0));
      const overflowHiddenListItems = categoryListItemElements.filter(
        (li) => li.offsetTop > categoryListBottomPosition
      );

      overflowHiddenListItems.forEach((li) => ((li.firstChild as any).tabIndex = -1));
    }, [categoryListRef, categoryListCollapsed]);

    const onCategoryClicked = (e, destinationCategoryId: string, destinationLink: string) => {
      const {handleCategoryClick} = globals;
      e.preventDefault();
      handleCategoryClick({destinationCategoryId, destinationLink});
    };

    const shouldIndentCategoryList = enableNewCategoryList && listHeaderCategory;
    const inlineCssVars = enableNewCategoryList ? {'--totalNumberOfCategories': categories.length} : {};

    return (
      <div className={s.container} data-hook={CategoryTreeDataHook.Container}>
        <ConditionalRender by={'gallery_showCategoriesTitle'}>
          {experiments.enabled(Experiments.GalleryA11yFixes) ? (
            <Text data-hook={CategoryTreeDataHook.SectionTitle} tagName={htmlTag}>
              <span className={s.sectionTitle}>{t('categoryTreeTitle')}</span>
            </Text>
          ) : (
            <h2 data-hook={CategoryTreeDataHook.SectionTitle} className={s.sectionTitle}>
              {t('categoryTreeTitle')}
            </h2>
          )}
        </ConditionalRender>
        {enableNewCategoryList && listHeaderCategory && (
          <ConditionalRender by={'gallery_showCategoryListHeader'} className={s.listHeader}>
            <CategoryListHeader dataHook={CategoryTreeDataHook.CategoryHeader} />
          </ConditionalRender>
        )}
        <div
          data-hook={CategoryTreeDataHook.List}
          style={inlineCssVars as React.CSSProperties}
          className={classNames(s.content, {
            [s.newCategoryListEnabled]: enableNewCategoryList,
          })}>
          <ul
            ref={categoryListRef}
            className={classNames(s.categoryList, {
              [s.categoryListCollapsed]: categoryListCollapsed,
            })}>
            {categories.map((category, i) => (
              <li
                key={category.id}
                className={classNames(s.categoryListItem, {[s.shouldIndent]: shouldIndentCategoryList})}>
                <TextButton
                  data-hook={CategoryTreeDataHook.CategoryOption.at(i)}
                  as="a"
                  className={classNames(
                    stylableClasses.option,
                    {[s.categoryLink]: enableNewCategoryList},
                    cssStates({
                      activeCategory: currentCategory.id === category.id,
                    })
                  )}
                  priority={TextButtonPriority.secondary}
                  href={category.categoryUrl}
                  onClick={(event) =>
                    enableNewCategoryList
                      ? navigateToCategory(category.id)
                      : onCategoryClicked(event, category.id, category.categoryUrl)
                  }>
                  {category.name === 'All Products' ? t('allProducts') : category.name}
                </TextButton>
              </li>
            ))}
          </ul>
          {enableNewCategoryList && (
            <ConditionalRender by={'shouldLimitCategoryList'}>
              <TextButton
                suffixIcon={categoryListCollapsed ? <ChevronDown /> : <ChevronUp />}
                data-hook={CategoryTreeDataHook.ShowMoreButton}
                onClick={() => setCategoryListCollapsed(!categoryListCollapsed)}
                className={classNames(stylableClasses.showMoreCategoriesTextButtonRoot, s.showMore, {
                  [s.shouldIndent]: shouldIndentCategoryList,
                })}>
                {categoryListCollapsed ? 'Show more' : 'Show less'}
              </TextButton>
            </ConditionalRender>
          )}
        </div>
      </div>
    );
  })
);
