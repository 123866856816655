import {AddToCartButtonIcon} from '../../../../../types/galleryTypes';
import {BuyNow, Plus, Select, ShoppingBag, ShoppingBagFill, Stores} from '@wix/wix-ui-icons-common/on-stage';

export const useAddToCartIcon = () => {
  const iconsMapper = {
    [AddToCartButtonIcon.ShoppingBag]: ShoppingBag,
    [AddToCartButtonIcon.ShoppingBagFill]: ShoppingBagFill,
    [AddToCartButtonIcon.BuyNow]: BuyNow,
    [AddToCartButtonIcon.SmallBag]: Stores,
    [AddToCartButtonIcon.Plus]: Plus,
    [AddToCartButtonIcon.Select]: Select,
  };

  return {iconsMapper};
};
